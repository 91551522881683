import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about/',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
    pathToRegexpOptions: { strict: true },
    meta: {
      title: 'About'
    }
  },
  {
    path: '/404',
    component: () => import('@/views/NotFound.vue'),
    meta: {
      title: 'Not Found'
    }
  },
  { path: '*', redirect: '/404' }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  linkExactActiveClass: 'active',
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = 'Chongling Zhu | ' + to.meta.title
  } else {
    document.title = 'Chongling Zhu'
  }
  next()
})

export default router
